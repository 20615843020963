@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400&display=swap");

body {
    font-family: "Lato", sans-serif;
    padding-top: 50px;
}

button {
    background: #e2e2e2;
    color: #000;
    padding: 10px;
    border: 0;
}

button:hover {
    background: #c9c9c9;
}

code {
    background: #dee2e6;
    padding: 10px;
    margin: 0 -15px;
    display: block;
}

/* nav stuff */
.hover-effect {
    transition: all 0.3s ease;
}

.hover-effect:hover {
    background-color: rgba(255, 255, 255, 0.1);
    transform: translateY(-2px);
}

/* account list stuff */
.account-row:hover {
    background-color: rgba(0, 123, 255, 0.03);
}
.account-name {
    color: #0d6efd;
    transition: color 0.2s;
}
.account-name:hover {
    color: #0a58ca;
}
.hover-effect {
    transition: all 0.3s ease;
}
.hover-effect:hover {
    transform: translateY(-2px);
}

.container {
    max-width: 95% !important;
}

.error {
    color: red;
}

.accountPage .row {
    padding: 10px 0;
}

.headingRow {
    background: #343a40;
    color: #fff;
    padding: 10px 0;
}

.table {
    margin-bottom: 0;
}

.tableRow {
    padding: 0 !important;
}

.table-buttons .btn {
    margin: 0 5px;
}

.content-topic-cell {
    max-width: 300px;
}

.pagemetainfo .row:nth-child(odd),
.headinginfo .row:nth-child(odd) {
    background: #e9ecef;
}

.next-steps .fa-link {
    margin-right: 5px;
}

.navbar-toggler {
    background: #fff !important;
}

.navbar-toggler:hover {
    background: #000;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
    margin-bottom: 0;
}

.next-steps .col-md-5 {
    margin-bottom: 3rem;
}

.spinner {
    position: absolute;
    display: flex;
    justify-content: center;
    height: 50vh;
    width: 50vw;
    background-color: white;
    top: 25%;
    bottom: 0;
    left: 25%;
    right: 0;
}

.result-block-container .result-block {
    opacity: 1;
}

.new-line {
    white-space: pre;
}

input#blogs,
input#posts {
    display: inline-block;
    width: 100px;
    margin-left: 10px;
}

.blogs,
.posts {
    display: inline-block;
    width: 70px;
    margin-left: 10px;
}

form.search-bar {
    margin: 3px 0 3px;
}

.search-bar .form-control {
    width: auto;
    display: inline;
}

.checkbox-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
}

.checkbox-item {
    flex: 1 1 20%; /* Adjust the 20% to control the number of columns */
    padding: 5px; /* Adjust padding for spacing */
}

.existing-content-select-buttons {
    margin: 10px 0;
}

.existing-content-select-buttons button {
    margin: 0 3px;
}

@media (max-width: 2000px) {
    .table-buttons {
        text-align: center;
    }

    .table-buttons .btn {
        font-size: 12px;
        margin: 2px 0;
        padding: 3px;
        display: block;
        width: 100%;
    }

    .table-buttons-internal .btn {
        width: 45%;
        margin: 2px;
        float: right;
    }
}

@media (max-width: 1400px) {
    .container {
        max-width: 95% !important;
    }

    .nav-link {
        font-size: 12px;
    }
}

@media (max-width: 992px) {
    .container {
        max-width: 100% !important;
    }

    .table-buttons-internal .btn {
        float: left;
    }

    h1 {
        font-size: 1.5rem !important;
    }

    h2 {
        font-size: 1.4rem !important;
    }

    h3,
    h4,
    h5,
    h6 {
        font-size: 1.2rem !important;
    }
}

@media (max-width: 768px) {
    .container {
        max-width: 100% !important;
    }

    .next-steps .col-md-5 {
        margin-bottom: 0;
    }

    .table-buttons .btn {
        margin: 2px 0;
        padding: 4px;
        display: block;
        width: 90%;
        font-size: 10px;
    }

    .nav-link {
        font-size: 16px;
    }
}

/* AccountContent update 3-14-25 */

/* AccountContent Styles */

/* Account Header */
.account-header-container {
    margin-bottom: 2rem;
}

.account-title {
    font-weight: 600;
    color: #333;
    margin-bottom: 0.5rem;
}

.account-url {
    color: #0056b3;
    text-decoration: none;
    font-size: 1rem;
    display: inline-block;
    margin-bottom: 0.5rem;
    transition: color 0.2s;
}

.account-url:hover {
    color: #003d7e;
    text-decoration: underline;
}

.account-stats {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.user-info {
    font-size: 1rem;
    margin-bottom: 1rem;
}

.stats-container {
    display: flex;
    margin-top: 0.5rem;
}

.stat-item {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: 1.5rem;
}

.stat-label {
    font-size: 0.875rem;
    color: #666;
}

.stat-value {
    font-size: 1.25rem;
    font-weight: 600;
    color: #0056b3;
}

/* Crawling Status */
.crawling-status {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.status-indicator {
    width: 12px;
    height: 12px;
    background-color: #28a745;
    border-radius: 50%;
    margin-right: 0.5rem;
    animation: pulse 1.5s infinite;
}

.crawl-text {
    margin: 0;
    font-size: 1rem;
    color: #28a745;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(40, 167, 69, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(40, 167, 69, 0);
    }
}

/* Content Form */
.content-form-container {
    margin-bottom: 2rem;
}

.content-form-container .card-header {
    background-color: #007bff;
}

.content-form-container .card-title {
    color: white;
    font-weight: 500;
}

.account-info-container {
    background-color: #f8f9fa;
    border-radius: 0.375rem;
    padding: 1rem;
    margin: 1.5rem 0;
}

/* Topics Input */
.topics-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    padding: 0.5rem;
    background-color: #f9f9f9;
    border-radius: 0.25rem;
    min-height: 48px;
    max-height: 150px;
    overflow-y: auto;
}

.topic-tag {
    display: inline-flex;
    align-items: center;
    background-color: #e9f0ff;
    border: 1px solid #bdd3ff;
    border-radius: 1rem;
    padding: 0.25rem 0.75rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    transition: all 0.2s ease;
}

.topic-tag:hover {
    background-color: #d8e5ff;
}

.topic-text {
    margin-right: 0.5rem;
    font-size: 0.9rem;
}

.topic-remove-btn {
    border: none;
    background: none;
    color: #6c757d;
    font-size: 1rem;
    line-height: 1;
    padding: 0 0.25rem;
    cursor: pointer;
    font-weight: bold;
    transition: color 0.2s ease;
}

.topic-remove-btn:hover {
    color: #dc3545;
}

.account-info-container h5 {
    margin-bottom: 1rem;
    font-weight: 600;
    color: #495057;
}

/* Content Table */
.content-table-container {
    margin-top: 2rem;
}

.content-table th {
    font-weight: 600;
    background-color: #f5f5f5;
}

.content-table .content-row {
    transition: background-color 0.2s;
}

.content-table .content-row:hover {
    background-color: #f9f9f9;
}

.content-topic {
    font-weight: 500;
    color: #333;
}

.content-length {
    color: #666;
}

.content-code {
    display: block;
    max-height: 100px;
    overflow: auto;
    font-size: 0.875rem;
    padding: 0.5rem;
    background-color: #f5f5f5;
    border-radius: 0.25rem;
}

.button-container {
    display: flex;
    justify-content: center;
}

.push-button {
    margin: 0 0.25rem;
    min-width: 80px;
}

/* Access Denied */
.access-denied-container {
    max-width: 600px;
    margin: 4rem auto;
}

/* Responsive Adjustments */
@media (max-width: 992px) {
    .account-stats {
        margin-top: 1.5rem;
        align-items: flex-start;
    }

    .stats-container {
        justify-content: flex-start;
    }

    .stat-item {
        align-items: flex-start;
        margin-left: 0;
        margin-right: 1.5rem;
    }
}

@media (max-width: 768px) {
    .push-button {
        padding: 0.375rem 0.5rem;
        font-size: 0.875rem;
    }
}

/* AccountContentExisting Styles */

/* Account Header and Content Styles - Common with AccountContent */
.account-header-container {
    margin-bottom: 2rem;
}

.account-title {
    font-weight: 600;
    color: #333;
    margin-bottom: 0.5rem;
}

.account-url {
    color: #0056b3;
    text-decoration: none;
    font-size: 1rem;
    display: inline-block;
    margin-bottom: 0.5rem;
    transition: color 0.2s;
}

.account-url:hover {
    color: #003d7e;
    text-decoration: underline;
}

.account-stats {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.user-info {
    font-size: 1rem;
    margin-bottom: 1rem;
}

.stats-container {
    display: flex;
    margin-top: 0.5rem;
}

.stat-item {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: 1.5rem;
}

.stat-label {
    font-size: 0.875rem;
    color: #666;
}

.stat-value {
    font-size: 1.25rem;
    font-weight: 600;
    color: #0056b3;
}

/* Crawling Status */
.crawling-status {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.status-indicator {
    width: 12px;
    height: 12px;
    background-color: #28a745;
    border-radius: 50%;
    margin-right: 0.5rem;
    animation: pulse 1.5s infinite;
}

.crawl-text {
    margin: 0;
    font-size: 1rem;
    color: #28a745;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(40, 167, 69, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(40, 167, 69, 0);
    }
}

/* Page Selection Section */
.form-section {
    margin-bottom: 2rem;
    border: 1px solid #e9ecef;
    border-radius: 0.375rem;
    overflow: hidden;
}

.form-section-header {
    background-color: #f8f9fa;
    padding: 1rem;
    border-bottom: 1px solid #e9ecef;
}

.form-section-header h4 {
    font-weight: 600;
    margin: 0;
    color: #495057;
}

.form-section-actions {
    display: flex;
    justify-content: flex-start;
    margin-top: 0.5rem;
}

.pages-selection-container {
    padding: 1rem;
    max-height: 1500px;
    overflow-y: auto;
    background-color: #fff;
}

/* Checkbox Section Improvements */
.checkbox-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 0.75rem;
}

.checkbox-item {
    display: flex;
    align-items: flex-start;
    padding: 0.375rem;
    transition: background-color 0.2s;
    border-radius: 0.25rem;
    overflow: hidden;
}

.checkbox-item:hover {
    background-color: #f8f9fa;
}

.custom-control {
    width: 100%;
    min-width: 0;
}

.custom-control-label {
    width: 100%;
    padding-left: 0.25rem;
    cursor: pointer;
    display: flex;
    line-height: 1.4;
}

.custom-control-label::before,
.custom-control-label::after {
    top: 0.25rem;
}

.page-link {
    color: #0056b3;
    text-decoration: none;
    transition: color 0.2s;
    margin-left: 0.375rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: calc(100% - 1.5rem);
    display: block;
}

.page-link:hover {
    color: #003d7e;
    text-decoration: underline;
}

/* Improve custom checkboxes */
.custom-control-input:checked ~ .custom-control-label::before {
    border-color: #007bff;
    background-color: #007bff;
}

.custom-control-label {
    padding-left: 0.25rem;
    cursor: pointer;
}

/* Form Styling */
.content-form-container {
    margin-bottom: 2rem;
}

.content-form-container .card-header {
    background-color: #007bff;
}

.content-form-container .card-title {
    color: white;
    font-weight: 500;
}

/* Content Table */
.content-table-container {
    margin-top: 2rem;
}

.content-table th {
    font-weight: 600;
    background-color: #f5f5f5;
}

.content-table .content-row {
    transition: background-color 0.2s;
}

.content-table .content-row:hover {
    background-color: #f9f9f9;
}

.content-topic {
    font-weight: 500;
    color: #333;
}

.content-length {
    color: #666;
}

.content-code {
    display: block;
    max-height: 100px;
    overflow: auto;
    font-size: 0.875rem;
    padding: 0.5rem;
    background-color: #f5f5f5;
    border-radius: 0.25rem;
}

/* Access Denied */
.access-denied-container {
    max-width: 600px;
    margin: 4rem auto;
}

/* Account Info Container */
.account-info-container {
    background-color: #f8f9fa;
    border-radius: 0.375rem;
    padding: 1rem;
    margin: 1.5rem 0;
}

.account-info-container h5 {
    margin-bottom: 1rem;
    font-weight: 600;
    color: #495057;
}

/* Responsive Adjustments */
@media (max-width: 992px) {
    .account-stats {
        margin-top: 1.5rem;
        align-items: flex-start;
    }

    .stats-container {
        justify-content: flex-start;
    }

    .stat-item {
        align-items: flex-start;
        margin-left: 0;
        margin-right: 1.5rem;
    }

    .checkbox-container {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
}

@media (max-width: 768px) {
    .checkbox-container {
        grid-template-columns: 1fr;
    }

    .form-section-actions {
        flex-direction: column;
    }

    .form-section-actions button {
        margin-bottom: 0.5rem;
        width: 100%;
    }
}
